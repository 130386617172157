<template v-show="selectedNavEntry.id === 80 || selectedNavEntry.id === 90">
  <div>
    <v-container>
      <v-toolbar flat>
        <v-toolbar-title>{{ toolbarTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-switch
          class="pa-3 pt-8"
          v-if="viewtype === 'list'"
          v-model="showOldEntries"
          label="Show old entries"
        ></v-switch>
        <v-btn-toggle dense v-model="viewtype" borderless group>
          <mksexpandingsearchfield
            v-if="true"
            placeholder="Filter by bundle or name"
            @searchChanged="searchChanged"
          >
          </mksexpandingsearchfield>

          <v-btn value="calender"><v-icon>event</v-icon></v-btn>
          <v-btn value="list"><v-icon>view_list</v-icon></v-btn>
        </v-btn-toggle>
      </v-toolbar>

      <v-row v-if="!init && items.length === 0">
        <v-col>
          <v-alert type="info">No reservations found</v-alert>
        </v-col>
      </v-row>
      <v-row v-show="viewtype === 'calender'">
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-tooltip right open-delay="700" close-delay="500">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    fab
                    text
                    small
                    @click="
                      calendarvalue = '';
                      $refs.calendar.scrollToTime('08:00');
                    "
                    ><v-icon>today</v-icon>
                  </v-btn>
                </template>
                <span>jump to today</span>
              </v-tooltip>
              <v-btn fab text small @click="$refs.calendar.prev()">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text small @click="$refs.calendar.next()">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn-toggle v-model="type" borderless dense>
                <v-btn
                  v-for="bt in types"
                  v-text="bt"
                  v-bind:key="bt"
                  :value="bt"
                >
                </v-btn>
              </v-btn-toggle>
              <!-- <v-select
                  v-model="type"
                  :items="types"
                  dense
                  outlined
                  hide-details
                  class="ma-2"
                  label="type"
                  style="width: 2em"
                ></v-select> -->
            </v-toolbar>
          </v-sheet>

          <v-sheet
            ref="movingTextouter"
            :height="type === 'week' ? this.tableheight : undefined"
          >
            <v-calendar
              ref="calendar"
              v-model="calendarvalue"
              :events="calendareventsFiltered"
              :weekdays="cal_weekday"
              :type="type"
              :event-overlap-mode="mode"
              :event-overlap-threshold="30"
              :event-color="getEventColor"
              :interval-format="intervalFormat"
              @click:event="eventClicked"
              @contextmenu:event="eventClicked"
            >
              <template v-slot:event="{ event }">
                <div
                  :title="
                    event.name !== null ? event.name.replace('<br>', ' ') : ''
                  "
                  :class="
                    'v-event-draggable' +
                    (event.other > 0 ? ' other' : '') +
                    (event.outside > 0 ? ' outside' : '')
                  "
                  style="white-space: normal"
                  v-html="buildEventText(event)"
                ></div>
                <v-icon
                  :class="event.outside > 0 ? ' outside' : ''"
                  dense
                  color="white"
                  v-if="
                    event.series &&
                    !(event.name == 'setup' || event.name == 'teardown')
                  "
                  style="position: absolute; right: 0px; bottom: 0px"
                  v-text="event.ide > 0 ? 'sync_disabled' : 'sync'"
                ></v-icon>
              </template>
            </v-calendar>
          </v-sheet>
        </v-col>
      </v-row>

      <div v-show="viewtype === 'list'">
        <v-data-table
          :items="calendareventsFiltered"
          fixed-header
          :height="tableheight"
          :headers="headers"
          item-key="ID_Resource_Bundle"
          :items-per-page="-1"
          hide-default-footer
          :sort-by="'start'"
        >
          <template v-slot:item="props">
            <tr
              @click="
                id_booked_slot = props.item.bookingId;
                getDetails(props.item.id_resource_bundle);
              "
            >
              <!-- <td>{{ formatEventDateTime(props.item.start) }}</td>
              <td>{{ formatEventDateTime(props.item.end) }}</td> -->
              <td>{{ formatDateCal(props.item.start, props.item.end) }}</td>
              <td>
                {{ props.item.name }}
                <v-icon small dense v-if="props.item.stp > 0" color="#5cd5c7"
                  >build</v-icon
                >
                <v-icon small dense v-if="props.item.trd > 0" color="#826df9"
                  >build</v-icon
                >
              </td>
              <td v-if="isScheduledView">{{ props.item.info }}</td>
            </tr>
          </template>
        </v-data-table>
        <v-card class="mt-4">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4">
                <v-icon left color="#5cd5c7"> build </v-icon>Setup Time required
              </v-col>
              <v-col cols="12" sm="4">
                <v-icon left color="#826df9"> build </v-icon>Teardown Time
                required
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
    <v-menu
      v-model="selectedOpen"
      :activator="selectedElement"
      offset-y
      :open-on-click="false"
    >
      <v-list dense nav>
        <v-list-item
          v-show="selectedSlot.other === 0 && offerJustThis"
          link
          @click="showException"
        >
          <v-list-item-icon><v-icon>event</v-icon> </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Open just this one</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-show="selectedSlot.other === 0"
          link
          @click="showReservationSlotRule"
        >
          <v-list-item-icon><v-icon>date_range</v-icon> </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Open the entire series</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <mksresourcereservation
      :bundle="bundle"
      :ID_Resource_Bundle_Reservation_Slot="id_booked_slot"
      v-if="showResourceReservation"
      :alternatelayout="AlternativeDetailsLayout"
      :showOld="showOldEntries"
      @close="closeResourceReservation"
    ></mksresourcereservation>
    <mksresourcereservationexception
      :reservationslot="selectedSlot"
      :title="bundle.details.bundle_title"
      :id_resource_bundle="bundle.details.id_resource_bundle"
      v-if="showResourceReservationException"
      :alternatelayout="AlternativeDetailsLayout"
      @close="closeResourceReservationException"
    ></mksresourcereservationexception>
  </div>
</template>

<style>
.v-calendar .v-calendar-weekly__intervals,
.v-calendar .v-calendar-daily__intervals {
  padding-right: 16px; /* Adjust based on scrollbar width */
}
</style>

<script>
import mksresourcereservation from "@/components/mksresourcereservation.vue";
import mksresourcereservationexception from "@/components/mksresourcereservationexception.vue";
// import { format } from 'date-fns'
// import { de } from 'date-fns/locale'

export default {
  name: "mksresourceuserview",

  components: {
    mksresourcereservation,
    mksresourcereservationexception,
  },

  data: () => ({
    showOldEntries: false,
    viewtype: "calender",
    filterValue: "",
    global_unique_id_rd: 0,
    bundle: {
      details: {
        id_resource_bundle: null,
        bundle_title: null,
        description: null,
        id_resource_root: null,
      },
      leaves: [],
    },
    id_booked_slot: 0,
    selectedElement: null,
    offerJustThis: true,
    selectedOpen: false,
    selectedSlot: {},
    showResourceReservation: false,
    showResourceReservationException: false,
    calendarevents: [],
    type: "week",
    types: ["month", "week"], //'day', '4day'],
    mode: "stack",
    modes: ["stack", "column"],
    cal_weekday: [1, 2, 3, 4, 5, 6, 0],
    cal_weekdays: [
      { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      // { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
    ],
    calendarvalue: "",
    loading: false,
    minuteSteps: 15,
    calendarSettings: {
      showBookable: true,
      showInvalid: false,
      showReservationEventsForThisBundle: true,
      showReservationEventsForOtherBundles: false,
    },
    calendarSettingsOrig: null,
    AlternativeDetailsLayout: false,
    tableheight: 500,
    init: true,
  }),

  props: {
    selectedNavEntry: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  mounted() {
    this.getReservations();
  },
  computed: {
    headers() {
      var h = [
        { text: "Date", value: "start" },
        { text: "Bundle", value: "name" },
      ];
      if (this.isScheduledView) {
        h.push({ text: "Booked by", value: "info" });
      }
      return h;
    },
    toolbarTitle() {
      return this.selectedNavEntry.id === 80
        ? "My Reservations"
        : "My Schedule - I have been booked for these reservations.";
    },
    isScheduledView() {
      return this.selectedNavEntry.id === 90;
    },
    calendareventsFiltered() {
      var filtered = this.calendarevents.filter((e) => {
        if (this.filterValue) {
          return (
            e.name.toLowerCase().includes(this.filterValue.toLowerCase()) ||
            (e.info &&
              e.info.toLowerCase().includes(this.filterValue.toLowerCase()))
          );
        } else {
          return true;
        }
      });

      if (!this.showOldEntries && this.viewtype === "list") {
        filtered = filtered.filter((e) => new Date(e.start) >= new Date());
      }

      if (this.type == "month") {
        filtered = filtered.filter(
          (e) => e.name !== "setup" && e.name !== "teardown"
        );
      }

      return filtered;
    },
  },
  methods: {
    updateTableHeight() {
      this.tableheight = this.container.offsetHeight - 215;
    },
    searchChanged(filterValue) {
      this.filterValue = filterValue;
    },
    scrollCalendarToDefault() {
      this.$nextTick(() => {
        if (this.$refs.calendar) {
          this.$refs.calendar.scrollToTime("08:00");
        }
      });
    },
    closeResourceReservation() {
      //this.$emit("closeReservation");
      // this.getDetails(false, true);
      this.showResourceReservation = false;
      this.getReservations();
    },
    buildEventText(cEvent) {
      return cEvent.name == "setup" || cEvent.name == "teardown"
        ? cEvent.name
        : this.formatEventTime(cEvent.start) +
            " - " +
            this.formatEventTime(cEvent.end) +
            "<br>" +
            cEvent.name +
            (cEvent.info ? "<br>" + cEvent.info : "");
    },
    getDetails(
      id_resource_bundle,
      showloading = false,
      reservationsonly = false
    ) {
      if (this.loading) {
        return;
      }

      this.loading = true;

      if (showloading) {
        this.showLoadingCircle = true;
      }
      var tempthis = this;

      this.$http
        .post("api/ResourceBundleGet", {
          id: id_resource_bundle,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else if (response.data.bundle) {
            if (reservationsonly) {
              this.bundle.TimeSlotsBookedAsEventList =
                response.data.bundle.TimeSlotsBookedAsEventList;
            } else {
              this.bundle.hasChanges = false;
              this.bundle = response.data.bundle;

              if (
                this.bundle.details
                  .smallest_possible_booking_period_in_minutes === null ||
                this.bundle.details
                  .smallest_possible_booking_period_in_minutes === 0
              ) {
                this.bundle.details.smallest_possible_booking_period_in_minutes = 60;
              }

              this.bundleOrig = JSON.parse(JSON.stringify(this.bundle.details));

              // if (this.open.length === 0) {
              //   this.open = [];
              //   this.open.push(tempthis.bundle.leaves[0].id_rd);
              // }

              // this.testtypes = this.bundle.details.testtypes;

              this.global_unique_id_rd = response.data.bundle.globunique_id;
            }

            // this.bundleimageerror = false;
            // this.bundleimagesource = this.getImgSrc(this.bundle.details);
          } else {
            this.show = false;
            this.$showMessage({
              content: "bundle not found",
              color: "warning",
            });
          }
          this.loading = false;
          tempthis.showResourceReservation = true;
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          this.loading = false;
          this.show = false;
        });
    },
    eventClicked({ nativeEvent, event }) {
      // if (event.other) {
      //   const open = () => {
      //     this.selectedElement = nativeEvent.target;
      //     requestAnimationFrame(() =>
      //       requestAnimationFrame(() => (this.selectedOpen = true))
      //     );
      //   };

      //   if (this.selectedOpen) {
      //     this.selectedOpen = false;
      //     requestAnimationFrame(() => requestAnimationFrame(() => open()));
      //   } else {
      //     open();
      //   }

      //   nativeEvent.stopPropagation();

      //   this.selectedSlot = event;
      //   this.lastbookingId = this.selectedSlot.bookingId;
      //   return;
      // }

      if (!this.calendarSettings.showReservationEventsForThisBundle) {
        this.calendarSettings.showReservationEventsForThisBundle = true;
        return;
      }
      if (event.bookingId > 0) {
        this.selectedSlot = event;
        this.lastbookingId = this.selectedSlot.bookingId;
        this.id_booked_slot = this.selectedSlot.bookingId;

        if (
          this.selectedSlot.series === true &&
          this.selectedSlot.name !== "setup" &&
          this.selectedSlot.name !== "teardown"
        ) {
          const open = () => {
            this.selectedElement = nativeEvent.target;
            const currentDate = new Date();
            const clickedDate = new Date(event.start);

            this.offerJustThis = clickedDate > currentDate;
            requestAnimationFrame(() =>
              requestAnimationFrame(() => (this.selectedOpen = true))
            );
          };

          if (this.selectedOpen) {
            this.selectedOpen = false;
            requestAnimationFrame(() => requestAnimationFrame(() => open()));
          } else {
            open();
          }

          nativeEvent.stopPropagation();
        } else {
          this.getDetails(this.selectedSlot.id_resource_bundle);
        }
      }
    },
    showReservationSlotRule() {
      if (this.selectedSlot) {
        this.getDetails(this.selectedSlot.id_resource_bundle);
      }
    },
    showException() {
      if (this.selectedSlot) {
        this.showResourceReservationException = true;
      }
    },
    closeResourceReservationException() {
      this.showResourceReservationException = false;
      this.getReservations();
    },
    getEventColor(event) {
      return event.color;
    },
    intervalFormat(interval) {
      return interval.time;
    },
    getReservations() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$http
        .get("api/Reservations", {
          params: {
            createSetupAndTeardownEvents: this.viewtype === "calender",
            schedule: this.isScheduledView,
          },
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            this.calendarevents = response.data;
          }

          this.$nextTick(function () {
            this.container = document.getElementsByClassName("v-main__wrap")[0];

            // zeitverzögerte Ausführung um richtige Höhe für container zu erhalten
            this.updateTableHeight();
            window.addEventListener("resize", this.updateTableHeight);
          });
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
        })
        .finally(() => {
          this.loading = false;
          this.scrollCalendarToDefault();
        });
    },
    formatEventDateTime(date) {
      const d = new Date(date);
      const day = d.getDate().toString().padStart(2, "0");
      const month = (d.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const year = d.getFullYear();
      const hours = d.getHours().toString().padStart(2, "0");
      const minutes = d.getMinutes().toString().padStart(2, "0");

      return `${day}.${month}.${year} ${hours}:${minutes}`;
    },
    formatEventTime(date) {
      return new Date(date).toLocaleTimeString("de-DE", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
    },
    formatDateCal(dateStart, dateEnd) {
      dateStart = new Date(dateStart);
      dateEnd = new Date(dateEnd);
      const dayStart = dateStart.getDate().toString().padStart(2, "0");
      const monthStart = (dateStart.getMonth() + 1).toString().padStart(2, "0");
      const yearStart = dateStart.getFullYear();
      const hoursStart = dateStart.getHours().toString().padStart(2, "0");
      const minutesStart = dateStart.getMinutes().toString().padStart(2, "0");

      const hoursEnd = dateEnd.getHours().toString().padStart(2, "0");
      const minutesEnd = dateEnd.getMinutes().toString().padStart(2, "0");

      if (this.areDatesEqual(dateStart, dateEnd)) {
        return `${dayStart}.${monthStart}.${yearStart} ${hoursStart}:${minutesStart} - ${hoursEnd}:${minutesEnd}`;
      } else {
        const dayEnd = dateEnd.getDate().toString().padStart(2, "0");
        const monthEnd = (dateEnd.getMonth() + 1).toString().padStart(2, "0");
        const yearEnd = dateEnd.getFullYear();

        return `${dayStart}.${monthStart}.${yearStart} ${hoursStart}:${minutesStart} - ${dayEnd}.${monthEnd}.${yearEnd}`;
      }
    },
    areDatesEqual(date1, date2) {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    },
  },

  watch: {
    selectedNavEntry: {
      handler() {
        this.getReservations();
      },

      deep: true,
    },

    viewtype: {
      handler() {
        this.getReservations();
      },
    },

    type: {
      handler() {
        this.scrollCalendarToDefault();
      },
    },
  },
};
</script>
